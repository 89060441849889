var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-time-condition-view"},[_c('div',{staticClass:"flex flex-col md:flex-row md:gap-2"},[_c('time-picker-input',{staticClass:"w-full mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'from',
          'clearable': false,
          'time24hr': true,
          'prefix': 'work_time_condition_',
          'validation': 'required'
        }
      }}}),_c('time-picker-input',{staticClass:"w-full mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'to',
          'clearable': false,
          'time24hr': true,
          'prefix': 'work_time_condition_',
          'validation': 'required'
        }
      }}})],1),_c('timezone-select')],1)}
var staticRenderFns = []

export { render, staticRenderFns }